//
// forms.scss
//

// Form-control light
.form-control-light {
  background-color: $input-light-bg !important;
  border-color: $input-light-bg !important;
}

// Form elements (Color and Range)
input.form-control[type="color"],
input.form-control[type="range"] {
  min-height: 39px;
}

// Custom select
.custom-select.is-invalid,
.form-control.is-invalid,
.custom-select:invalid,
.form-control:invalid,
.custom-select.is-valid,
.form-control.is-valid,
.custom-select:valid,
.form-control:valid {
  &:focus {
    box-shadow: none !important;
  }
}

// Comment box
.comment-area-box {
  .form-control {
    border-color: $gray-300;
    border-radius: $input-border-radius $input-border-radius 0 0;
  }

  .comment-area-btn {
    background-color: $gray-100;
    padding: 10px;
    border: 1px solid $gray-300;
    border-top: none;
    border-radius: 0 0 $input-border-radius $input-border-radius;
  }
}

// Search box
.search-bar {
  .form-control {
    padding-left: 40px;
    padding-right: 20px;
    border-radius: 30px;
  }

  span {
    position: absolute;
    z-index: 10;
    font-size: 16px;
    line-height: $input-height;
    left: 13px;
    top: -2px;
    color: $text-muted;
  }
}

.search-input {
  width: 330px;

  .form-control {
    border-color: $gray-300;
  }

  .remove-search-text {
    right: 60px;
    top: 50%;
    transform: translateY(-50%);
    color: $gray-500;
  }
}

// Show Password
.password-eye {
  &:before {
    font-family: feather !important;
    content: "\e86a";
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    vertical-align: middle;
    line-height: 1.2;
    font-size: 16px;
  }
}

.show-password {
  .password-eye {
    &:before {
      content: "\e86e";
    }
  }
}

.form-group {
  margin-bottom: 1.5rem;
}


.hidden-input {
  width: 0;
  height: 0;
  visibility: hidden;
}

.input-group {
  &.input-wrapper {
    display: inline-block;
    position: relative;
  }

  .text-input {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  textarea.text-input {
    overflow: unset;
    text-overflow: unset;
    white-space: unset;
    resize: none;
    min-height: unset;
  }

  .custom-tooltip {
    position: absolute;
    left: 0;
    bottom: 100%;
    background: $gray-700;
    color: white;
    padding: 5px;
    border-radius: 4px !important;
    // white-space: nowrap;
    max-width: 100%;
    /* Ensure tooltip text stays on one line */
    margin-top: 5px;
    /* Adjust margin to fit your design */
    z-index: 1050;
  }
}

.required-input {
  &::after {
    content: "*";
    position: absolute;
    top: 0;
    right: -10px;
    font-size: 12px;
    color: $danger;
  }
}

.form-control {
  &.disabled-editable-input {
    &:disabled {
      opacity: 1 !important;
    }

    &:focus {
      border-color: transparent;
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.form-control {
  &:disabled {
    opacity: 0.6;
  }
}