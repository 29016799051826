//
// multi-select.scss
//

.ms-container {
  background: transparent
    url("../../../../public/images/plugin/multiple-arrow.png") no-repeat 50% 50%;
  width: auto;
  max-width: 370px;

  .ms-list {
    box-shadow: none;
    border: $input-border-width solid $input-border-color;

    &.ms-focus {
      box-shadow: none;
      border: $input-border-width solid $input-focus-border-color;
    }
  }
  .ms-selectable {
    background-color: $input-bg;
    li {
      &.ms-elem-selectable {
        border: none;
        padding: 5px 10px;
        color: $gray-600;
      }
      &.ms-hover {
        background-color: $primary;
        color: $white;
      }
    }
  }
  .ms-selection {
    background-color: $input-bg;
    li {
      &.ms-elem-selection {
        border: none;
        padding: 5px 10px;
        color: $gray-600;
      }
      &.ms-hover {
        background-color: $primary;
        color: $white;
      }
    }
  }
}

.ms-selectable {
  box-shadow: none;
  outline: none !important;
}

.ms-optgroup-label {
  font-weight: $font-weight-medium;
  font-family: $font-family-secondary;
  color: $dark !important;
  font-size: 13px;
  padding-left: 5px !important;
}
