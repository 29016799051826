.hidden-scroll {

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */

  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

@for $i from 0 through 5 {
  .line-clamp-#{$i} {
    -webkit-line-clamp: #{$i};
  }
}

.text-ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-break-space {
  white-space: break-spaces;
}

.disabled {
  opacity: .6;
}

.cursor-drag {
  cursor: grab;
}

.scroll-y {
  overflow-y: auto;
}

.scroll-hidden {
  overflow-y: hidden;
}

.none-border {
  border-width: 0 !important;
}

.text-nowrwap {
  white-space: nowrap;
}

.text-prewrap {
  white-space: pre-wrap;
}

.cursor-pointer {
  cursor: pointer;
}