// popover.scss
//

.popover-header {
  margin-top: 0;
}

.react-tooltip {
  max-width: 100%;
}

.tooltip-inner {
  max-width: 100%;
  font-size: 90%;
  background: #222;
  color: #fff;
  padding: 8px 16px;
  white-space: break-spaces;
  text-align: start;
  z-index: 1009;

  .tooltip-arrow {
    &::before {
      border-top-color: #222 !important;
    }
  }
}

.right-tooltip {
  .tooltip-arrow {
    &::before {
      border-top-color: none;
      border-right-color: #222 !important;
    }
  }
}