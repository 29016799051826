// 
// buttons.scss
//

.btn {
    color: $white;


    &:focus {
        box-shadow: none;
    }

    .icon {
        svg {
            width: 1rem;
            height: 1rem;
            margin-left: 0.1rem;
            margin-top: -2px;
        }
    }
}

.btn-rounded {
    border-radius: 2em;
}

//
// light button
// 
.btn-light,
.btn-white {
    color: $gray-900;
}

.btn-white {
    border-color: $gray-300;

    &:hover,
    &:focus {
        background-color: $gray-300;
        border-color: $gray-400;
    }
}

//
// Link buttons
//
// Make a button look and behave like a link
.btn-link {
    font-weight: $font-weight-normal;
    color: $link-color;
    background-color: transparent;

    &:hover {
        color: $link-hover-color;
        text-decoration: $link-hover-decoration;
        background-color: transparent;
        border-color: transparent;
    }

    &:focus {
        text-decoration: $link-hover-decoration;
        border-color: transparent;
        box-shadow: none;
    }

    &:disabled {
        color: $btn-link-disabled-color;
        pointer-events: none;
    }

    // No need for an active state here
}


// 
// Button Extra Small Size
// 

.btn-xs {
    padding: .2rem .6rem;
    font-size: .75rem;
    border-radius: .15rem;
}

// Soft variants
@mixin btn-variant-soft($bg, $color) {
    background-color: rgba($bg, $btn-soft-bg-opacity);
    color: $color;
    border-color: rgba($color, $btn-soft-bg-opacity);

    &:focus {
        box-shadow: none;
    }

    &:hover,
    &:focus {
        background-color: fade-in(rgba($bg, $btn-soft-bg-opacity), .05);
        color: $color;
    }
}

@each $color, $value in $theme-colors {
    .btn-soft-#{$color} {
        @include btn-variant-soft($value, $value);
    }
}

//
// Alternate buttons
//
@each $color,
$value in $theme-colors {
    .btn-outline-#{$color} {
        @include button-outline-variant($value);

        &:focus {
            box-shadow: none;
        }
    }
}

.btn-upload {
    border: 1px dashed #767676;
    color: #767676;

    &:hover {
        border: 1px dashed #767676;
        color: #767676;
    }
}

.btn-upload-lg {
    border: 1px dashed #767676;
    border-radius: 12px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 0;
    flex-direction: column;
    color: #767676;
    font-size: 12px;
    min-height: 138px;
    color: $input-placeholder-color;
}

.btn-upload-sm {
    border-radius: 25px;
    color: white;
    font-size: 12px;
    background-color: #5369f8;
    padding: 2px 10px;
}

.add-more-wrap {
    width: 90%;
    height: 20px;
    background-color: transparent;
    position: absolute;
    bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    button {
        display: none;
        position: absolute;
        height: 0px;
        top: 0px;
    }

    &:hover {
        bottom: -10px;

        button {
            display: inline-block;
        }
    }

    &.add-more-wrap-child {
        width: 30px;
        bottom: 50%;
        transform: translateY(50%);
        right: -9px;

        &:hover {
            bottom: 50%;

            button {
                bottom: unset;
                right: 0;
            }
        }
    }
}