// 
// dropdown.scss
//

.dropdown-menu {
    box-shadow: $box-shadow;
    animation-name: DropDownSlide;
    animation-duration: .3s;
    animation-fill-mode: both;
    font-size: $font-size-base;
    top: 100%;
    
    &.show {
        top: 100%!important;
        z-index: 1004;
    }

    i {
        display: inline-block;
    }
}

.dropdown-menu-end {
    right: 0!important;
    left: auto!important;
}

.dropdown-menu[data-popper-placement^=right],
.dropdown-menu[data-popper-placement^=top],
.dropdown-menu[data-popper-placement^=left]{
    top: auto !important;
    animation: none !important;
}

@keyframes DropDownSlide {
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
    0% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px)
    }
}


// Dropdown Large (Custom)

@media (min-width: 600px) {
    .dropdown-lg {
        width: $dropdown-lg-width;
    }
}

// Dropdown with Icons
.dropdown-icon-item {
    display: block;
    border-radius: 3px;
    line-height: 34px;
    text-align: center;
    padding: 15px 0 9px;
    display: block;
    border: 1px solid transparent;
    color: $dropdown-link-color;

    img {
        height: 24px;
    }

    span {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &:hover {
        background-color: $dropdown-link-hover-bg;
    }
}

// Dropdown size
@include media-breakpoint-up(lg) {
    .dropdown-mega-menu-xl{
        width: 40rem;
    }
    
    .dropdown-mega-menu-lg{
        width: 26rem;
    }
}