//
// tables.scss
//

th {
  font-weight: $font-weight-bold;
}

//Table centered (Custom)
.table-centered {

  td,
  th {
    vertical-align: middle !important;
  }
}

// Table

.table-header-nowrap {
  tr {
    white-space: nowrap;
  }
}

.table-nowrap {

  th,
  td {
    white-space: nowrap;
  }
}


.row-loading {
  height: 50px;
}

// Custom table components (Custom)
.table {
  .text-truncate {
    width: 100px;
    min-width: 90%;
  }

  .table-user {
    img {
      height: 30px;
      width: 30px;
    }
  }

  td {
    vertical-align: middle;
  }
}

.action-icon {
  color: $gray-600;
  font-size: 1.2rem;
  display: inline-block;
  padding: 0 3px;

  &:hover {
    color: $gray-700;
  }
}

.table-collapse {
  .hiddenRow {
    padding: 0;
    border: 0;
  }

  &.table-bg {
    >tbody {
      td {
        background-color: #fff;
      }
    }
  }

  &.striped {
    >tbody {


      >tr:nth-child(4n + 1) {
        td {
          background-color: $table-striped-bg;
        }
      }
    }
  }

  .btn-collapse {
    border-color: $gray-300;

    &:hover {
      background-color: $gray-200;
    }
  }
}

.table-spin-nested-loading {
  position: relative;

  &>div>.spin {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;
    display: block;
    width: 100%;
    height: 100%;
    max-height: 400px;
  }

  .spin {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    position: absolute;
    display: none;
    color: $gray-700;
    text-align: center;
    vertical-align: middle;
    opacity: 0;
    transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }

  .spin-spinning {
    opacity: 1;
  }

  &>div>.spin .spin-dot {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -10px;
  }

  .spin-blur {
    clear: both;
    opacity: 0.5;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
  }

  .visually-hidden {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
  }
}

.table-fixed {
  table-layout: fixed;

  .col-fixed {
    word-wrap: break-word;
    word-break: break-all;
    overflow-wrap: break-word;
    white-space: break-spaces;
  }
}

.pagination-custom {
  display: flex;
  justify-content: space-between;

  .pagination {
    display: flex;
    justify-content: flex-end;
    padding-left: 0;
    list-style: none;
    margin: 0;

    .page-item {
      margin-left: 5px;
      margin-right: 5px;
      cursor: pointer;

      &.disabled {
        cursor: not-allowed;
        opacity: 0.4;
      }

      &.active {
        .page-link {
          z-index: 3;
          color: #fff;
          background-color: $primary;
          border: none;
        }
      }
    }

    .page-link {
      border-radius: 50%;
      padding: 0.5rem 0.75rem;
      position: relative;
      color: #343a40;
      text-decoration: none;
      background-color: #fff;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
      border-color: 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      margin: 0 3px;
      border: none;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        color: #343a40;
        background-color: #f6f6f7;
      }
    }
  }
}

.simulation-table {
  >.table {
    min-width: 1200px;
  }

  .table-detail .table {
    margin: 0;

    tr {
      white-space: nowrap;
    }
  }
}





.table-header-sticky {
  thead {

    tr {
      border-width: 0;
    }

    th {
      position: sticky;
      top: 0px;
      z-index: 1000 !important;
      outline: 1px solid $table-border-color;
      background-color: #e2e3e5 !important;
      border-width: 0;

      &.sticky {
        z-index: 1001 !important;
      }
    }

  }

  .table-bordered {

    th,
    td {
      border: 1px solid $table-border-color;
    }
  }
}

.border-table {
  border:
    1px solid $table-border-color;
}

.border-table-left {
  border-left: 1px solid $table-border-color;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  background-color: #fff !important;
  z-index: 3 !important;
  outline: 1px solid $table-border-color;
}

.table-drag {
  position: relative;

  &::after {
    z-index: 1003;
    content: "";
    position: absolute;
    top: 0;
    left: 1.5rem;
    border-left: 1px solid #e2e7f1;
    height: calc(100% - 3rem);
  }

}

.border-none {
  border-width: 0px 0px !important;
}

.table-container {
  transform: translate3d(0, 0, 0);
}