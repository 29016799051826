// 
// backgrounds.scss
//


@each $color,
$value in $theme-colors {
    .bg-soft-#{$color} {
        background-color: rgba(($value), 0.25) !important;
    }
}

.bg-ghost {
    opacity: 0.4;
}
.dragging {
  background-color: rgb(180, 252, 252);
  border: 1px solid rgb(92, 255, 255);
}