// 
// nav.scss
//


// Bootstrap override - turn nav tab with broder

.nav-tabs {
    

    .nav-item {
        margin-bottom: -1px;
    }

    li {
        a {
            border-color: transparent !important;
            padding: 0.625rem 1.25rem;
        }
        &.active {
          border-top: 1px solid gray;
          border-left: 1px solid gray;
          border-right: 1px solid gray;
          border-bottom: 1px solid white;
        }
    }
}

// pills
.nav-tabs,.nav-pills {
    > li {
        > a {
            color: $gray-700;
            font-weight: $font-weight-bold;
        }
    }
}

.nav-pills {
    background: $body-bg;
    border-radius: $border-radius;
    padding: 6px 2px;

    .nav-link {
        background-color: transparent;
        color: $body-color;
    }

    .nav-link.active,
    .show>.nav-link {
        color: $body-color;
        background-color: $card-bg;
        box-shadow: $box-shadow-sm;
    }
}


//Navtab custom
.navtab-bg {
    li {
        >a {
            background-color: $gray-300;
        }
    }
}

.tab-content {
  height: calc(100vh - 160px);
  overflow-y: auto;
  padding: 10px;
  border: 1px solid gray;
}
