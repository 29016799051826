// 
// modal.scss
//

// Title text within header
.modal-title {
    margin-top: 0;
    font-size: 18px;
}

.modal-content {
  .text-truncate {
    max-width: 300px;
  }
}

// Modal full width
.modal-full-width {
    width: 90%;
    height: 90%;
    max-width: none;
}

// Modal Positions
.modal-top {
    margin: 0 auto;
}

// Right Modal
.modal-right {
    position: absolute;
    right: 0;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    height: 100%;
    margin: 0;
    background-color: $modal-content-bg;
    align-content: center;
    transform: translate(25%,0) !important;

    button.close {
        position: fixed;
        top: 20px;
        right: 20px;
        z-index: 1;
    }
}

.modal {
    &.show {
        .modal-right {
            transform: translate(0,0) !important;
        }
    }
    &.modal-static {
      .modal-dialog {
        transform: none;
      }
    }
}

// Bottom modal
.modal-bottom {
    display: flex;
    flex-flow: column nowrap;
    -ms-flex-pack: end;
    justify-content: flex-end;
    height: 100%;
    margin: 0 auto;
    align-content: center;
}

// Colored modal header
.modal-colored-header {
    color: $white;
    border-radius: 0;

    .close {
        color: $white !important;
    }
}

// Model
.modal-open {
    padding-right: 0px !important;
    padding-left: 0px !important;
}

@include media-breakpoint-up(sm) {
  .modal-content {
    .text-truncate {
      max-width: 340px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .modal-content {
    .text-truncate {
      max-width: 640px;
    }
  }
}