@for $i from 0 through 250 {

  //padding
  .p-b-#{$i} {
    padding-bottom: #{$i}px;
  }

  .p-t-#{$i} {
    padding-top: #{$i}px;
  }

  .p-r-#{$i} {
    padding-right: #{$i}px;
  }

  .p-l-#{$i} {
    padding-left: #{$i}px;
  }

  .p-x-#{$i} {
    padding-left: #{$i}px;
    padding-right: #{$i}px;
  }

  .p-y-#{$i} {
    padding-top: #{$i}px;
    padding-bottom: #{$i}px;
  }

  .p-a-#{$i} {
    padding: #{$i}px;
  }

  //margin
  .m-t-#{$i} {
    margin-top: #{$i}px;
  }

  .m-b-#{$i} {
    margin-bottom: #{$i}px;
  }

  .m-r-#{$i} {
    margin-right: #{$i}px;
  }

  .m-l-#{$i} {
    margin-left: #{$i}px;
  }

  .m-l-#{$i}-n {
    margin-left: -#{$i}px;
  }

  .m-x-#{$i} {
    margin-left: #{$i}px;
    margin-right: #{$i}px;
  }

  .m-y-#{$i} {
    margin-top: #{$i}px;
    margin-bottom: #{$i}px;
  }

  .m-a-#{$i} {
    margin: #{$i}px;
  }

  // height with percent

  .height-by-percent-#{$i} {
    height: ($i / 100) * 100%;
  }

  .width-by-percent-#{$i} {
    width: ($i / 100) * 100%;
  }

  //font size
  .f-s-#{$i} {
    font-size: #{$i}px;
  }

  // Z-index
  .z-i-#{$i} {
    z-index: #{$i};
  }

  // Z-index
  .z-i-#{$i}-i {
    z-index: #{$i} !important;
  }


  .r-negative-#{$i} {
    right: -#{$i}px;
  }

  .r-#{$i} {
    right: #{$i}px;


  }

  .bt-#{$i} {
    bottom: #{$i}px;
  }

}

@for $i from 990 through 1100 {
  .z-i-#{$i} {
    z-index: #{$i};
  }

}

@for $i from 1 through 500 {
  .height-by-px-#{$i} {
    height: #{$i}px;
  }

  .width-by-px-#{$i} {
    width: #{$i}px;
  }

  .min-width-by-px-#{$i} {
    min-width: #{$i}px;
  }

  .max-width-by-px-#{$i} {
    max-width: #{$i}px;
  }

  .min-height-by-px-#{$i} {
    min-height: #{$i}px;
  }

  .max-height-by-px-#{$i} {
    max-height: #{$i}px;
  }
}

.max-height-by-px-700 {
  max-height: 700px;
}

.height-by-px-670 {
  height: 670px;
}

.height-auto {
  height: auto !important;
}

.full-page {
  width: 100vw;
  height: 100vh;
}

.max-vh-85 {
  max-height: 85vh;
}


.position-top-center {
  top: 50%;
  transform: translateY(-50%);
}


$base-rem: 16px;
$target-rem: 1.7; // Our target rem value

@function px-to-rem($px-value, $base: $base-rem, $target: $target-rem) {
  @return ($px-value / $base) * $target;
}

$left: (
  0px,
  50px,
  75px,
  200px,
  225px,
  350px,
  325px,
  440px,
  590px,
  150px,
  390px,
  540px
);

@each $value in $left {
  .left-#{$value} {
    left: $value;
  }
  .left-#{$value}-rem {
    left: calc($value + 1.7rem)
  }

  .left-#{$value}-2rem {
    left: calc($value + 3.4rem)
  }

  .right-#{$value} {
    right: $value;
  }

  .right-#{$value}-rem {
    right: calc($value + 1.7rem)
  }
}


.vw-75 {
  width: 75vw;
}
