//
// layouts.scss
//

body[data-layout-width="boxed"] {
    #wrapper {
        max-width: $boxed-layout-width;
        margin: 0 auto;
        box-shadow: $box-shadow-lg;
    }

    .navbar-custom {
        max-width: $boxed-layout-width;
        margin: 0 auto;
    }

    .footer {
        margin: 0 auto;
        max-width: calc(#{$boxed-layout-width} - #{$leftbar-width});
    }

    &[data-sidebar-size="condensed"] {
        .footer {
            max-width: calc(#{$boxed-layout-width} - #{$leftbar-width-condensed});
        }
    }

    &[data-sidebar-size="compact"] {
        .footer {
            max-width: calc(#{$boxed-layout-width} - #{$leftbar-width-sm});
        }
    }
}

// Body min-height set
@include media-breakpoint-up(md) {
    body[data-layout-width="boxed"][data-sidebar-size="condensed"] {
        .content-page {
            min-height: calc(#{$body-min-height} - #{$leftbar-width-condensed});
        }
    }
}

// Scrollable Layout
@include media-breakpoint-up(xl) {
    body[data-layout-menu-position="scrollable"]:not([data-sidebar-size="condensed"]):not([data-sidebar-size="compact"]):not([data-layout-mode="two-column"]) {
        padding-bottom: 0;

        #wrapper {
            display: flex;
        }

        .navbar-custom,
        .topnav {
            position: absolute;
        }

        .left-side-menu {
            position: relative;
            min-width: $leftbar-width;
            max-width: $leftbar-width;
            padding: 20px 0 calc(#{$topbar-height} + 20px);
        }

        .content-page {
            margin-left: 0;
            width: 100%;
            padding-bottom: 60px;
        }
    }
}

.loading-page {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1000;

    .spin {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        display: block;
        width: 100%;
        height: 100%;

        box-sizing: border-box;
        margin: 0;
        padding: 0;
        font-size: 14px;
        font-variant: tabular-nums;
        line-height: 1.5715;
        list-style: none;
        font-feature-settings: "tnum";
        color: $gray-700;
        text-align: center;
        vertical-align: middle;
        opacity: 0;
        transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);

        .spin-dot {
            position: absolute;
            top: 50%;
            left: 50%;
            margin: -10px;
        }
    }

    .spin-spinning {
        opacity: 1;
    }

    .spin-blur {
        clear: both;
        opacity: 0.5;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        pointer-events: none;
    }

    .visually-hidden {
        position: absolute !important;
        width: 1px !important;
        height: 1px !important;
        padding: 0 !important;
        margin: -1px !important;
        overflow: hidden !important;
        clip: rect(0, 0, 0, 0) !important;
        white-space: nowrap !important;
        border: 0 !important;
    }
}

.border-transparent {
    border-color: transparent;
}