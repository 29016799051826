.login {
  display: flex;
  align-items: center;
  justify-content: center;
  .background {
    object-fit: cover;
  }
  .logo {
    width: 30px;
    margin-right: 10px;
  }

  .google-login-btn {
    background-color: rgb(255, 255, 255);
    color: $gray-800;
    height: 55px;
    border: 1px solid $gray-300;
    border-radius: 4px;
    text-align: center;
    font-size: 18px;
    line-height: 48px;
    transition: background-color 0.218s ease 0s, border-color 0.218s ease 0s,
      box-shadow 0.218s ease 0s;
    cursor: pointer;
    user-select: none;
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    padding-right: 20px;
    .google-img {
      float: left;
    }
    &:hover {
      border-color: $gray-500;
    }
  }

  .sidebar-login {
    background-image: url("../../../../src/assets/images/background.png");
    background-position: 50% 50%;
    min-height: 650px;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
