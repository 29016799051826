.custom-toast {
  min-width: 200px;
  max-width: 600px;
  min-height: 45px;
  right: 20px;
  top: 40px;
  border-radius: 5px;
  padding: 12px 25px 12px 20px;
  .btn-close {
    padding: 7px;
    font-size: 9px;
    top: 50%;
    transform: translateY(-50%);
  }
}
.alert-wrap {
  z-index: 1061;
  width: 100%;
  height: 1px;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
}